.chat-box {
    position: fixed;
    bottom: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    top: 10%;
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 1.5;
    z-index: 100;
}

.conversation {
    height: 60vh;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.conversation div {
    margin-bottom: 10px;
}

.conversation span {
    display: inline-block;
    margin-right: 5px;
}

.input-form {
    display: flex;
    margin-top: 10px;
}

.input-field {
    width: 100%;
    height: 60px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    font-size: 16px;
}

.input-form button {
    width: 80px;
    height: 60px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    background-color: #067df7;
    color: white;
    font-size: 16px;
}

.chat-box-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
}

.chat-box-loading {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.chat-box-loading div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background-color: #067df7;
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.chat-box-loading div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
}

@keyframes loading {
    0% {
        top: 6px;
        height: 51px;
    }
    50%, 100% {
        top: 19px;
        height: 26px;
    }
}